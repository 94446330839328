<template>
  <div
    class="col-12"
    :style="showAddNewSummary ? 'background: #ECEFF0':'border: 1px solid #ECEFF0'"
  >
    <div class="pr-3 pl-3" v-if="showAddNewSummary" style="padding-bottom: 40px">
      <div class="row">
        <div class="col-sm-6"></div>
        <div class="col-sm-6">
          <label>{{$t('summary.conclusionSelectDocument')}}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 content-summary">
          <div>
            <div class="row pb-3">
              <div class="col-sm-3">{{$t('summary.conclusionContent')}}</div>
              <div class="col-sm-9">
                <textarea
                  id="noteMessage"
                  class="form-control"
                  :placeholder="$t('document.note.enterNote')"
                  rows="4"
                  v-model="summaryData.content"
                ></textarea>
              </div>
            </div>
            <!-- <div class="row pb-3">
              <div class="col-sm-3">{{$t('summary.conclusionStatus')}}</div>
              <div class="col-sm-9 item-center" style="display: flex">
                <div
                  class="custom-control custom-radio mr-2"
                  v-for="(status, index) in listSummaryStatus"
                  :key="status+index"
                >
                  <input
                    type="radio"
                    class="custom-control-input"
                    :id="status.value"
                    :value="status.value"
                    v-model="summaryData.status"
                    name="example1"
                  />
                  <label class="custom-control-label" :for="status.value">{{$t(status.text)}}</label>
                </div>
              </div>
            </div> -->
          </div>
          <div class="row pb-3">
            <div class="col-sm-3"></div>
            <div class="col-sm-9">
              <button
                type="button"
                class="btn save-btn text-white"
                style="padding: 2px 15px;"
                @click="saveConclusion"
              >{{$t('summary.saveConclusion')}}</button>
              <button
                type="button"
                class="btn"
                style="padding: 2px 15px; margin-left: 5px"
                @click="onClickCancel"
              >{{$t('summary.cancelConclusion')}}</button>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div>
            <div class="pl-4 pr-4 pt-3 class-border-div scrollbar" id="style-5">
              <!-- <div v-for="(item, index) in documentList" :key="item + index" class>
                <div class="my-input">
                  <div class="pb-2" style="display: flex">
                    <div class="" style="align-self: center; display: inline">
                      <img :src="item.poster_url" style="width: 15px; height: 20px;" />
                    </div>
                    <div class="col-sm-11 pl-2" style="display: inline; word-break: break-all;">
                      <a :href="item.url" target="_blank" class="">{{item.name}}</a>
                    </div>
                    <div class="" style="align-self: center;">
                      <b-check
                        style="float: right"
                        v-model="item.select"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <div 
                v-for="(item, index) in documentList" 
                :key="item+index"
              >
                <div class="pb-1">
                  {{item.title=='other'?$t('summary.otherDocument'):item.title}}
                </div>
                <div class="pl-3 pb-1" 
                  v-for="(document_item, index) in item.document" 
                  :key="document_item+index"
                  style="display: flex"
                >
                  <!-- <img :src="defaultPDF"/> -->
                  <div class="" style="align-self: center; display: inline">
                    <img :src="defaultPDF" style="width: 15px; height: 20px;" />
                  </div>
                  <div class="col-sm-11 pl-2" style="display: inline; word-break: break-all;">
                    <a :href="document_item.url" target="_blank" class="">{{document_item.name}}</a>
                  </div>
                  <div class="" style="align-self: center;">
                    <b-check
                      style="float: right"
                      v-model="document_item.select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3 upload-document">
            <!-- {{documentFileName}} -->
            <label class="pl-4 m-0" for="document-for-summary" style="align-self: center; cursor: pointer;">
              <img src="../assets/icons/path.svg" >
            </label>
            <label for="document-for-summary" class=" custom-file-upload ml-2 pt-2 pb-2">
              <span class="document-name" style="">{{
                documentFileName ? documentFileName : $t("document.uploadNewDocument")
              }}</span>
            </label>
            <label class="m-0 pt-2 pb-2 pl-2 pr-2" style="align-self: center; float: right">
              <button
                v-if="documentFileName"
                type="button"  
                class="btn save-btn text-white" 
                style="padding: 0 10px; width: 55px; height: 26px;"
                @click="uploadFileToServer"
              >{{$t('common.save')}}</button>
            </label>
            <input v-on:change="onFileChange" id="document-for-summary" type="file" accept="application/pdf"/>
          </div>
        </div>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import stringUtils from "utils/stringUtils";
import defaultPDF from "enum/summary";
import { uploadFileToMediaAgent } from 'utils/functionUtils';
// import dateUtils from "utils/dateUtils";
export default {
  data: () => ({
    event_id: null,
    listSummaryStatus: stringUtils.SummaryStatus,
    documentList: [],
    showAddNewSummary: false,
    summaryData: {
      id: 0,
      content: "",
      status: 1,
      // endTime: ""
      // SelectDocument: ""
    },
    defaultPDF: defaultPDF,
    summaryList: [],
    // upload document
    documentFileName: null,
    documentFileSize: null,
    mediaServerToken: null,
    mediaServerDomain: null,
  }),
  components: {},
  watch: {
    GET_SUMMARY_LIST_DATA: function() {
      this.summaryList = [];
      this.GET_SUMMARY_LIST_DATA.forEach(data => {
        let item = {
          id: data.id,
          content: data.content,
          status: data.status,
          end_time: data.end_time
        };
        this.summaryList.push(item);
      });
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    GET_DOCUMENT_FOR_SUMMARY_DATA(){
      this.documentList = []
      this.GET_DOCUMENT_FOR_SUMMARY_DATA.forEach(data=>{
        let item={
          'title': data.title,
          'document': data.document
        }
        this.documentList.push(item);
      })
    }
  },
  computed: {
    ...mapGetters([
      "GET_SUMMARY_LIST_DATA",
      "GET_SNACK_BAR",
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "GET_DOCUMENT_FOR_SUMMARY_DATA"
    ])
  },
  methods: {
    onShowAddNewSummary: function() {
      this.showAddNewSummary = true;
    },
    getDocumentSelect: function() {
      let documentListSelect = [];
      this.documentList.forEach(data => {
        let document_select_data=data.document
            .filter(x => (x.select!=undefined && x.select==true) )
        if (document_select_data.length!=0) {
          document_select_data.map((x) => {
            documentListSelect.push(x.id)
          })
        }
      });
      return documentListSelect;
    },
    validateData: function() {
      let validatedResult = "OK";
      for (let key in this.summaryData) {
        if (this.summaryData[key] === null || this.summaryData[key] === "") {
          validatedResult =
            "Xin vui lòng nhập/chọn " +
            this.$t(
              "summary.conclusion" +
                key.charAt(0).toUpperCase() +
                key.slice(1)
            );
          break;
        } else {
          //
        }
      }
      return validatedResult;
    },
    saveConclusion: function() {
      let result = this.validateData();
      if (result == "OK") {
        let data = {
          id: this.summaryData.id,
          content: this.summaryData.content,
          status: this.summaryData.status,
          // end_time: dateUtils.formatDate(
          //   this.summaryData.endTime,
          //   dateUtils.STATIC_FORMAT_TIME_DATE,
          //   dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T
          // ),
          document: this.getDocumentSelect(),
          event: this.event_id
        };
        this.CREATE_SUMMARY(data)
          .then(
            function() {
              let filter = {
                params: {
                  event: this.event_id
                }
              };
              this.GET_SUMMARY_LIST(filter);
              this.GET_DOCUMENT_FOR_SUMMARY(filter);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
              this.summaryData = {
                id: 0,
                content: "",
                status: 0,
              };
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger"
              });
            }.bind(this)
          );
      } else {
        this.ON_SHOW_TOAST({
          message: result,
          styleType: "danger"
        });
      }
    },
    onClickCancel() {
      this.showAddNewSummary = false;
    },
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    uploadFileToServer: async function() {
      // Invalid if file bigger 10MB
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } else {
        let url = await uploadFileToMediaAgent(this.documentFile, "documents/" + this.event_id)
        if (url != null) {
          let filter = {
            name: this.documentFileName,
            url: url,
            event: this.event_id,
            document_status: 1
          }
          this.SAVE_DOCUMENT_SUMMARY(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success"
              });
              this.documentFileName=null;
              let filter = {
                params: {
                  event: this.event_id
                }
              };
              this.GET_DOCUMENT_FOR_SUMMARY(filter);
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          )
        }
      }
    },
    ...mapActions([
      "CREATE_SUMMARY",
      "ON_SHOW_TOAST",
      "GET_SUMMARY_LIST",
      "GET_TOKEN_MEDIA_SERVER",
      "SAVE_DOCUMENT_SUMMARY",
      "GET_DOCUMENT_FOR_SUMMARY"
    ])
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id
      }
    };
    this.GET_SUMMARY_LIST(filter);
    this.GET_TOKEN_MEDIA_SERVER();
    this.GET_DOCUMENT_FOR_SUMMARY(filter);
  }
};
</script>

<style lang="scss" scoped>
@import "../style/color.scss";
.item-center {
  align-self: center;
}
.class-border-div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: white;
}
.save-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.content-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scrollbar {
  height: 180px;
  overflow-y: auto;
}
.custom-file-upload {
  display: inline-block;
  width: 100%;

  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}
input[type="file"] {
  display: none;
}
.upload-document{
  background: #ffffff; 
  display: flex; 
  border-radius: 0.25rem; 
  border: 1px solid #ccc;
  word-break: break-all;
}
</style>