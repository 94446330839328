<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('summary.summaryInfo')"
      size="xl"
      centered
      id="modal-center"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-6 content-summary">
            <div class="form-group">
              <label>{{ $t("summary.conclusionContent") }}</label>
              <textarea
                id="noteMessage"
                class="form-control"
                :placeholder="$t('document.note.enterNote')"
                rows="4"
                v-model="summaryData.content"
              ></textarea>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>{{ $t("summary.conclusionSelectDocument") }}</label>
              <div>
                <div
                  class="pl-4 pr-4 pt-3 class-border-div scrollbar"
                  id="style-5"
                >
                  <div
                    v-for="(item, index) in documentList"
                    :key="item + index"
                  >
                    <div class="pb-1">
                      {{
                        item.title == "other"
                          ? $t("summary.otherDocument")
                          : item.title
                      }}
                    </div>
                    <div
                      class="pl-3 pb-1"
                      v-for="(document_item, index) in item.document"
                      :key="document_item + index"
                      style="display: flex"
                    >
                      <!-- <img :src="defaultPDF"/> -->
                      <div class="" style="align-self: center; display: inline">
                        <img
                          :src="defaultPDF"
                          style="width: 15px; height: 20px;"
                        />
                      </div>
                      <div
                        class="col-sm-11 pl-2"
                        style="display: inline; word-break: break-all;"
                      >
                        <a :href="document_item.url" target="_blank" class="">{{
                          document_item.name
                        }}</a>
                      </div>
                      <div class="" style="align-self: center;">
                        <b-check
                          style="float: right"
                          v-model="document_item.enable"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 upload-document">
                <!-- {{documentFileName}} -->
                <label
                  class="pl-4 m-0"
                  for="document-for-summary-detail"
                  style="align-self: center; cursor: pointer;"
                >
                  <img src="../../assets/icons/path.svg" />
                </label>
                <label
                  for="document-for-summary-detail"
                  class=" custom-file-upload ml-2 pt-2 pb-2"
                >
                  <span class="document-name" style="">{{
                    documentFileName
                      ? documentFileName
                      : $t("document.uploadNewDocument")
                  }}</span>
                </label>
                <label
                  class="m-0 pt-2 pb-2 pl-2 pr-2"
                  style="align-self: center; float: right"
                >
                  <button
                    v-if="documentFileName"
                    type="button"
                    class="btn save-btn text-white"
                    style="padding: 0 10px; width: 55px; height: 26px;"
                    @click="uploadFileToServer"
                  >
                    {{ $t("common.save") }}
                  </button>
                </label>
                <input
                  v-on:change="onFileChange"
                  id="document-for-summary-detail"
                  type="file"
                  accept="application/pdf"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >
            {{ $t("summary.saveConclusion") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import defaultPDF from "enum/summary";
import { uploadFileToMediaAgent } from 'utils/functionUtils';
export default {
  props: {
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data: () => ({
    isShow: false,
    title: "",
    event_id: null,
    //
    documentList: [],
    summaryData: {
      id: 0,
      content: "",
      documentList: [],
      status: 1,
    },
    defaultPDF: defaultPDF,
    // upload document
    documentFileName: null,
    documentFileSize: null,
    mediaServerToken: null,
    mediaServerDomain: null,
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR", "GET_DOCUMENT_FOR_SUMMARY_DATA", "GET_TOKEN_MEDIA_SERVER_DATA"]),
  },
  watch: {
    GET_DOCUMENT_FOR_SUMMARY_DATA() {
      this.documentList = [];
      this.GET_DOCUMENT_FOR_SUMMARY_DATA.forEach((data) => {
        let item = {
          title: data.title,
          document: data.document,
        };
        this.documentList.push(item);
      });
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    }
  },
  created() {
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    // let filter = {
    //   params: {
    //     event: event_id
    //   }
    // };
    // this.GET_TOKEN_MEDIA_SERVER();
    // this.GET_DOCUMENT_FOR_SUMMARY(filter)
  },
  methods: {
    validateData: function() {
      let validatedResult = "OK";
      for (let key in this.summaryData) {
        if (this.summaryData[key] === null || this.summaryData[key] === "") {
          validatedResult =
            this.$t('common.inputFieldNull') +
            this.$t(
              "summary.conclusion" + key.charAt(0).toUpperCase() + key.slice(1)
            );
          break;
        } else {
          //
        }
      }
      return validatedResult;
    },
    // On confirm
    onConfirm: function() {
      let result = this.validateData();
      if (result == "OK") {
        let data = {
          id: this.summaryData.id,
          content: this.summaryData.content,
          status: this.summaryData.status,
          document: this.getDocumentSelect(),
          event: this.event_id,
        };
        this.UPDATE_SUMMARY(data)
          .then(
            function() {
              this.isShow = false;
              let filter = {
                params: {
                  event: this.event_id,
                },
              };
              this.GET_SUMMARY_LIST(filter);
              this.GET_DOCUMENT_FOR_SUMMARY(filter);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success",
              });
              this.summaryData = {
                id: 0,
                content: "",
                status: 0,
              };
            }.bind(this)
          )
          .catch(
            function(error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger",
              });
            }.bind(this)
          );
      } else {
        this.ON_SHOW_TOAST({
          message: result,
          styleType: "danger",
        });
      }
    },
    /**
     * On show modal confirm
     */
    onShowModalConfirm: function(data) {
      this.summaryData.id = data && data.id;
      this.summaryData.content = data && data.content;
      this.summaryData.documentList = data && data.document;
      this.summaryData.status = data && data.summaryStatus;
      this.showDocumentSelect();
      this.isShow = true;
    },
    // show document
    showDocumentSelect: function() {
      this.documentList.forEach((data) => {
        data.document.filter((x) => {
          let index = this.summaryData.documentList.findIndex(
            (doc) => doc.id === x.id
          );
          x.enable = index != -1 ? true : false;
        });
      });
    },
    //
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    uploadFileToServer: async function () {
      // Invalid if file bigger 10MB
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } else {
        let url = await uploadFileToMediaAgent(this.documentFile, "documents/" + this.event_id)
        if (url != null) {
          let filter = {
            name: this.documentFileName,
            url: url,
            event: this.event_id,
            document_status: 1
          }
          this.SAVE_DOCUMENT_SUMMARY(filter).then(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success"
              });
              this.documentFileName=null;
              let filter = {
                params: {
                  event: this.event_id
                }
              };
              this.GET_DOCUMENT_FOR_SUMMARY(filter);
            }.bind(this)
          ).catch(
            function () {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          )
        }
      }
    },
    getDocumentSelect: function() {
      let documentListSelect = [];
      this.documentList.forEach((data) => {
        let document_select_data = data.document.filter(
          (x) => x.enable != undefined && x.enable == true
        );
        if (document_select_data.length != 0) {
          document_select_data.map((x) => {
            documentListSelect.push(x.id)
          })
        }
      });
      return documentListSelect;
    },
    ...mapActions([
      "GET_DOCUMENT_FOR_SUMMARY",
      "ON_SHOW_TOAST",
      "UPDATE_SUMMARY",
      "GET_SUMMARY_LIST",
      "SAVE_DOCUMENT_SUMMARY"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.btn-submit {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
.item-center {
  align-self: center;
}
.class-border-div {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: white;
}
.save-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.content-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scrollbar {
  height: 180px;
  overflow-y: auto;
}
.custom-file-upload {
  display: inline-block;
  width: 100%;

  border-radius: 5px;
  cursor: pointer;
  margin: 0;
}
input[type="file"] {
  display: none;
}
.upload-document {
  background: #ffffff;
  display: flex;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
  word-break: break-all;
}
</style>
