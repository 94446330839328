<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('common.confirm')"
      centered
      id="modal-center"
    >
      <div class="container">
        <div class="row">
          <div
            class="col-md-12 mb-2"
            style="padding: 0 !important; margin-top: 0 !important;"
          >
            <span>{{ $t("document.currentDocumentViewer") }}: </span>
            <div style="display: block;">
              <div
                v-for="(approverName, index) in approversName"
                :key="approverName + index"
                style="display: inline-block;"
              >
                <div class="mr-1 mt-1" style="padding: 0px 4px 4px 4px; background-color: #41b883; color: #fff; border-radius: 5px;">
                  <span class="meeting-font-medium">
                    {{ approverName.full_name }}
                  </span>
                  <span @click="removeCurrentViewer(approverName)" class="meeting-font-small" style="background-color: #369a6e; padding: 2px 5px 2px 5px; cursor: pointer; border-radius: 5px;">X</span>
                </div>
              </div>
            </div>
          </div>
          <p>{{ $t("document.assignDocumentViewer") }}</p>
          <div class="col-md-12" style="padding: 0 !important;">
            <VueMutiselect
              ref="approverMutiSelect"
              :optionData="approverList"
              :customLabel="customLabel"
              :labelText="labelTextValue"
              :trackByText="trackByTextValue"
              :placeHolder="$t('mission.selectPerson')"
              :close-on-select="false"
              @select="getAssigner"
            ></VueMutiselect>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >
            {{ $t("common.accept") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueMutiselect from "Components/VueMutiselect";
import functionUtils from "utils/functionUtils";
import DocumentStatus from "enum/documentStatus";
import Enum from 'enum/enum';
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    VueMutiselect,
  },
  data: () => ({
    isShow: false,
    approverList: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    approverSelected: [],
    eventId: null,
    documentId: null,
    isUpdate: false,
    approversName: [],
    event:{
      name:''
    },
    currentUser:{
      id: 0,
      lastName: '',
      firstName: ''
    },
    documentFileName: '',
    documentOwnerId: 0,
    approversVueMutiselect: [],
    userList: [],
    groupList: []
  }),

  computed: {
    ...mapGetters(["GET_USER_IN_EVENT_LIST_DATA", "GET_SNACK_BAR", "EVENT_DETAIL_DATA", "GET_CURRENT_ACCOUNT"]),
  },
  watch: {
    EVENT_DETAIL_DATA: function(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  created() {
    this.eventId = sessionStorage.getItem("event_id");
  },
  methods: {
    /**
     * Remove current viewer
     */
    removeCurrentViewer: function (viewer) {
      for (let i = 0, size = this.approversName.length; i < size; i++) {
        if (viewer.id == this.approversName[i].id) {
          this.approversName.splice(i, 1)
          this.approverSelected = this.approverSelected.filter((item) => {
            return item.id != viewer.id
          })
          break;
        }
      }
      this.approverList.push(viewer)
    },
    /**
     * Get attendees speakers
     */
    getUsersInEvent: function() {
      // Get viewer document
      if (this.isUpdate) {
        let filter = {
          params: {
            documentId: this.documentId,
          },
        };
        this.GET_DOCUMENT_REVIEWER(filter).then(
          function(res) {
            let data = res.data;
            
            this.approverSelected = [];
            this.approversName = [];
            for (let i = 0, size = data.length; i < size; i++) {
              if (!functionUtils.isNull(data[i].user)) {
                this.approverSelected.push({id: data[i].user.id, is_group: data[i].is_group});
                // Get full name
                let fullname =
                  data[i].user.last_name + " " + data[i].user.first_name;
                // Get id
                let id = data[i].user.id;
                let url_avatar = data[i].user.profile && data[i].user.profile.url_avatar
                let is_group = data[i].is_group
                this.approversName.push({ id: id, full_name: fullname, url_avatar: url_avatar, is_group: is_group});
              }
            }
            // Get user in event list
            let attendesSpeakersFilter = {
              params: {
                event: this.eventId,
                bandGroup: true
              }
            };
            this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter).then(
              function(res) {
                let data = res.data;
                // Get user in event
                this.approverList = [];
                if (!functionUtils.isNull(data)) {
                  data.forEach((element) => {
                    let full_name =
                      element.last_name + " " + element.first_name;
                    let item = {
                      id: element.id,
                      full_name: full_name,
                      url_avatar:
                        (element.profile &&
                          element.profile.url_avatar != null) ||
                        element.profile.url_avatar != ""
                          ? element.profile.url_avatar
                          : null,
                      is_group: element.is_group
                    };
                    let isExist = false
                    for (let i = 0, size = this.approversName.length; i < size; i++) {
                      if (this.approversName[i].id == item.id) {
                        isExist = true
                      }
                    }
                    if (!isExist) {
                      this.approverList.push(item);
                    }
                  });
                }
              }.bind(this)
            );
          }.bind(this)
        );
      } else {
        this.approverSelected = []
        this.approversName = []
        // Get user in event list
        let attendesSpeakersFilter = {
          params: {
            event: this.eventId,
            bandGroup: true
          }
        };
        this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter).then(
          function(res) {
            let data = res.data;
            // Get user in event
            this.approverList = [];
            if (!functionUtils.isNull(data)) {
              data.forEach((element) => {
                let full_name =
                  element.last_name + " " + element.first_name;
                let item = {
                  id: element.id,
                  full_name: full_name,
                  url_avatar:
                    (element.profile &&
                      element.profile.url_avatar != null) ||
                    element.profile.url_avatar != ""
                      ? element.profile.url_avatar
                      : null,
                  is_group: element.is_group
                };
                this.approverList.push(item);
              });
            }
          }.bind(this)
        );
      }
    },
    /**
     * Get uploader
     */
    getAssigner(data) {
      this.approversVueMutiselect = [];
      data.forEach((item) => {
        // this.approverSelected.push({id: item.id, is_group: item.is_group});
        // let check_item_in_list = this.approverSelected.findIndex(x=>x.id===item.id)
        // console.log(aaa);
        // if 
         this.approversVueMutiselect.push({id: item.id, is_group: item.is_group});
      });
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    // On confirm
    onConfirm: function() {
      let filter = {
        documentId: this.documentId,
        status: DocumentStatus.APPROVED,
        reviewers: this.approverSelected.concat(this.approversVueMutiselect),
      };
      this.getUserListAndGroupListNotification(this.approverSelected.concat(this.approversVueMutiselect))
      if (this.isUpdate) {
        this.UPDATE_DOCUMENT_REVIEWER(filter)
          .then(
            function() {
              this.isShow = false;
              this.sendNotification();
              this.sendEmail();
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success",
              });
              this.approverSelected = [];
              this.$refs.approverMutiSelect.clearAll();
              this.approversVueMutiselect = [];
            }.bind(this)
          )
          .catch(
            function() {
              this.isShow = false;
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      } else {
        this.APPROVE_DOCUMENT_REVIEW(filter)
          .then(
            function() {
              this.isShow = false;
              this.ON_SHOW_TOAST({
                message: this.$t("common.success"),
                styleType: "success",
              });
              this.sendNotification();
              this.sendEmail();
              this.approverSelected = [];
              this.$refs.approverMutiSelect.clearAll();
              this.approversVueMutiselect = [];
            }.bind(this)
          )
          .catch(
            function() {
              this.isShow = false;
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    /**
     * On show modal confirm
     */
    onShowModalConfirm: function(documentId, isUpdate, documentName, documentOwnerId) {
      this.isUpdate = isUpdate;
      this.documentId = documentId;
      this.getUsersInEvent();
      this.isShow = true;
      this.documentFileName = documentName;
      this.documentOwnerId = documentOwnerId;
    },
    sendNotification: function(){
      let data = {
        event_id: this.eventId,
        // message_title: this.event.name,
        // message_body: this.$t('notification.document.approval', {'0': this.documentFileName, '1': this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname}),
        action_code: this.isUpdate ? TemplateCategoriesEnum.UPDATE_USER_VIEW_DOCUMENT : TemplateCategoriesEnum.DOCUMENT_APPROVAL,
        template_type: Enum.TemplateType.Notification,
        document_name: this.documentFileName,
        notification_type_code: this.isUpdate ? Enum.NotificationType.UpdateDocumentReviewer : Enum.NotificationType.DocumentApproval
      }
      if(!this.isUpdate){
        data['user_approval_name'] = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname
        data['user_id'] = this.documentOwnerId
      } else {
        data['user_list'] = this.userList
        data['group_list'] = this.groupList
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // let smsContent = this.$t('notification.document.approval', {'0': this.documentFileName, '1': this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname})

      let smsData = {
        event_id: this.eventId,
        // content: functionUtils.uniDecodeString(smsContent),
        user_id: this.documentOwnerId,
        action_code: this.isUpdate ? TemplateCategoriesEnum.UPDATE_USER_VIEW_DOCUMENT : TemplateCategoriesEnum.DOCUMENT_APPROVAL,
        template_type: Enum.TemplateType.SMS,
        document_name: this.documentFileName,
      }
      if(!this.isUpdate){
        smsData['user_approval_name'] = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let data = {
        action_code: this.isUpdate ? TemplateCategoriesEnum.UPDATE_USER_VIEW_DOCUMENT : TemplateCategoriesEnum.DOCUMENT_APPROVAL,
        template_type: Enum.TemplateType.Email,
        event_id: this.eventId,
        user_id: this.documentOwnerId,
        document_name: this.documentFileName
      }
      if(!this.isUpdate){
        data['user_approval_name'] = this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    getUserListAndGroupListNotification: function(dataList) {
      this.userList = []
      this.groupList = []
      dataList.forEach(v => {
        if (v.is_group) {
          this.groupList.push(v.id)
        } else {
          this.userList.push(v.id)
        }
      })

    },
    ...mapActions([
      "GET_USER_IN_EVENT_LIST",
      "APPROVE_DOCUMENT_REVIEW",
      "ON_SHOW_TOAST",
      "UPDATE_DOCUMENT_REVIEWER",
      "GET_DOCUMENT_REVIEWER",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../style/color.scss";
.btn-submit {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
</style>
